import { cn } from '@/Lib'
import { PageProps } from '@/types'
import { usePage } from '@inertiajs/react'
import {
  MotionProps,
  animate,
  motion,
  useMotionValue,
  useTransform
} from 'framer-motion'
import { FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react'
import { ZAR } from './Intl'

function useThrottle(value: number, limit: number) {
  const [throttledValue, setThrottledValue] = useState(value)
  const lastRan = useRef(Date.now())

  useEffect(() => {
    const handler = setTimeout(
      () => {
        if (Date.now() - lastRan.current >= limit) {
          setThrottledValue(value)
          lastRan.current = Date.now()
        }
      },
      limit - (Date.now() - lastRan.current)
    )

    return () => {
      clearTimeout(handler)
    }
  }, [value, limit])

  return throttledValue
}

type MoneyProps = {
  value: number
  maximumFractionDigits?: number
  // Make use of the hide_amounts value to hide input value
  canHide?: boolean
} & HTMLAttributes<HTMLSpanElement> &
  MotionProps

export const Money: FC<MoneyProps> = ({
  value,
  maximumFractionDigits = 2,
  canHide = true,
  className,
  ...props
}) => {
  const { hide_amounts } = usePage<PageProps>().props

  // Throttle value to 100ms to prevent animation breaking
  const throttledValue = useThrottle(value, 100)
  const valueMotion = useRef(useMotionValue(value)).current

  useEffect(() => {
    animate(valueMotion, throttledValue, { duration: 0.3 })
  }, [throttledValue, valueMotion])

  // Create the transform with NaN handling
  const zar = useTransform(valueMotion, (current) => {
    if (isNaN(current) || !isFinite(current)) {
      // Handle NaN or infinite values
      return ZAR(0, maximumFractionDigits)
    }
    return ZAR(current, maximumFractionDigits)
  })

  const hiddenZar = useMemo(() => {
    const zar = ZAR(value, maximumFractionDigits)
    return (
      zar.substring(0, 2) +
      Array(zar.substring(2).length).fill('\u2022').join('')
    )
  }, [maximumFractionDigits, value])

  return (
    <motion.span className={cn('tabular-nums', className)} {...props}>
      {canHide && hide_amounts ? hiddenZar : zar}
    </motion.span>
  )
}
